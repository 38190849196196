.lobby__custom2 {
  @extend %box-neat;
  @include hoverflow;
  background: $c-bg-box;
  min-height: 20em;
  grid-area: support;
  margin-bottom: 5em;
}


.daily-feed {
  &__updates {
    margin-inline-start: 24px;
    border-inline-start: 2px solid $c-contours;
    margin-bottom: 1.5em;
  }

  &__update {
    padding-inline-start: 25px;
    margin-top: 1.5em;

    table {
      display: none;
    }

    &__marker {
      float: inline-start;
      width: 32px;
      height: 32px;
      padding: 5px;
      margin-top: -5px;
      margin-inline-start: -42px;
      background-color: $c-contours;
      border: 3px solid $c-bg-box;
      border-radius: 50%;

      &.nobg {
        background: $c-bg-box;
        border: 2px solid $c-contours;
      }
    }

    &__day {
      @extend %flex-center;
      display: inline;
      font-size: 1.15em;
      font-weight: bold;
      color: $c-contours;
      time {
        font-size: 1em;
        opacity: 1;
      }
      &:hover {
        color: $c-brag;
      }
    }
    @include rendered-markdown(
      $element-margin: 0.6em,
      $h1: false,
      $h2: false,
      $table: false,
      $img: false,
      $list: false
    );
    h3 {
      font-size: 1.2em;
      color: $c-font-dim;
      border-bottom: $border;
    }
    h4 {
      font-size: 1.15em;
    }
  }
}
