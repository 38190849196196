@import 'pool';
@import 'hook-chart';
@import 'hook-filters';
@import 'hook-list';

.lobby__app {
  @extend %flex-column;

  @include lobby-app-size;

  user-select: none;

  .lobby-nope & {
    display: none;
  }

  &__content {
    @extend %box-neat;

    flex: 1 1 100%;
    position: relative;
    background-color: $c-bg-box;
    background-position: 50% 54%;
    background-size: 90% 90%;
    background-image: url(/assets/images/gray-crown.svg);
    background-repeat: no-repeat;

    @include if-light {
      background-image: url(/assets/images/blue-crown.svg);
    }


    @include if-transp {
      background: $m-bg--fade-20;
    }
    overflow-x: hidden;
    overflow-y: auto;
  }

  .lredir {
    background: $c-bg-box !important;
    display: flex;

    .spinner {
      width: 100px;
      height: 100px;
    }
  }

  .toggle {
    position: absolute;
    padding: 0.6em;
    cursor: pointer;
    z-index: 2;
    font-size: 1.3em;

    &.toggle-filter {
      @include inline-end(0);
    }

    @include transition;

    &:hover {
      color: $c-accent;
    }
  }

  .gamesFiltered {
    color: $c-accent;

    @include transition;

    &:hover {
      color: $c-accent-dim;
    }
  }
}

@media (max-width: at-most($xx-small)) {
  .lreal_time,
  .lseeks .hooks__list {
    font-size: 0.9em;
  }

  .lobby__app .toggle {
    padding: 0.4em;
  }
}
