.lobby__footer {
  display: flex;
  align-content: flex-start;
  justify-content: center;
  flex-flow: row wrap;
  font-size: 0.85em;
  font-weight: bold;
  grid-area: winner;

  a {
    @extend %page-text;

    margin-inline-end: 1.2em;
    white-space: nowrap;

    @media (any-pointer: coarse) {
      padding: 0.5em;
    }

    @include transition(color);

    &:hover {
      color: $c-link;
    }
  }
}

.connect-links {
  @extend %flex-center;
  justify-content: center;
  flex: 1 0 100%;
  margin-top: 0.5em;

  a {
    font-weight: normal;
  }
}
